import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "common_show_panel" }
const _hoisted_2 = { class: "common_title" }
const _hoisted_3 = { class: "show_list" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["src"]

import { getCurrentInstance, computed } from 'vue'
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'CommonShowPanel',
  props: {
    title: {},
    list: {},
    type: {}
  },
  setup(__props: any) {

  const { appContext: { config : { globalProperties } } } = (getCurrentInstance() as any);
  const baseUrl = computed(() => { return globalProperties.baseUrl })
  

  function eventClick(item: any) {
    window.open(item.toLink || item.link)
  }

return (_ctx: any,_cache: any) => {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.$props.title), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.$props.type == 1)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.$props.list, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "show_item_1 pointer",
              onClick: ($event: any) => (eventClick(item)),
              key: index,
              style: _normalizeStyle({ backgroundColor: (item as any).bcolor })
            }, [
              ((item as any).url)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: baseUrl.value + (item as any).url
                  }, null, 8, _hoisted_5))
                : _createCommentVNode("", true),
              ((item as any).svg)
                ? (_openBlock(), _createBlock(_component_svg_icon, {
                    key: 1,
                    color: (item as any).scolor,
                    "icon-class": (item as any).svg
                  }, null, 8, ["color", "icon-class"]))
                : _createCommentVNode("", true),
              _createElementVNode("p", {
                innerHTML: (item as any).title
              }, null, 8, _hoisted_6)
            ], 12, _hoisted_4))
          }), 128))
        : _createCommentVNode("", true),
      (_ctx.$props.type == 2)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.$props.list, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "show_item_2 pointer",
              onClick: ($event: any) => (eventClick(item)),
              key: index
            }, [
              ((item as any).url)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: baseUrl.value + (item as any).url
                  }, null, 8, _hoisted_8))
                : _createCommentVNode("", true),
              ((item as any).svg)
                ? (_openBlock(), _createBlock(_component_svg_icon, {
                    key: 1,
                    color: (item as any).scolor,
                    "icon-class": (item as any).svg
                  }, null, 8, ["color", "icon-class"]))
                : _createCommentVNode("", true)
            ], 8, _hoisted_7))
          }), 128))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})