import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "home" }
const _hoisted_2 = { class: "panel_one" }
const _hoisted_3 = { class: "panel_one" }
const _hoisted_4 = {
  key: 0,
  class: "fixed_box"
}
const _hoisted_5 = ["src"]

import Banner from './components/Banner.vue';
  import CommonShowPanel from './components/CommonShowPanel.vue';
  import NewListPanel from './components/NewListPanel.vue';
  import { CONST } from '@/types/const'
  import { onMounted, ref, getCurrentInstance } from 'vue'

  
export default /*@__PURE__*/_defineComponent({
  ...{
    components: {
      Banner,
      NewListPanel,
      CommonShowPanel
    }
  },
  __name: 'Index',
  setup(__props) {

  
  let linkList = ref([])
  let modalData = ref({})
  let listOne = ref([])
  let listTwo = ref([])
  const { appContext: { config : { globalProperties } } } = (getCurrentInstance() as any);

  onMounted(() => {
    showPage()
  })

  function showPage() {
    globalProperties.$API.LinkList().then((res: any) => {
      if(res.data.code == 200) {
        linkList.value = res.data.data;
      }
    })
    globalProperties.$API.ModalCon().then((res: any) => {
      if(res.data.code == 200) {
        res.data.data.url = globalProperties.baseUrl + res.data.data.url;
        modalData.value = res.data.data;
      }
    })
    globalProperties.$API.HomeArticleType({
      parentClassification: 0
    }).then((res: any) => {
      if(res.data.code == 200) {
        listOne.value = res.data.data.slice(0, 3);
        listTwo.value = res.data.data.slice(3, 6);

      }
    })
  }


return (_ctx: any,_cache: any) => {
  const _component_icon_close_circle = _resolveComponent("icon-close-circle")!
  const _component_a_link = _resolveComponent("a-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Banner, { api: "BannerList" }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Banner, { api: "PhotoList" }),
      (_unref(listOne).length > 0)
        ? (_openBlock(), _createBlock(NewListPanel, {
            key: 0,
            width: "606px",
            api: "ArticleList",
            list: _unref(listOne)
          }, null, 8, ["list"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(CommonShowPanel, {
      title: "标准化服务",
      type: 1,
      list: (_unref(CONST) as any).standardServiceList
    }, null, 8, ["list"]),
    _createElementVNode("div", _hoisted_3, [
      (_unref(listOne).length > 0)
        ? (_openBlock(), _createBlock(NewListPanel, {
            key: 0,
            api: "ArticleList",
            width: "570px",
            list: _unref(listTwo)
          }, null, 8, ["list"]))
        : _createCommentVNode("", true),
      _createVNode(NewListPanel, {
        width: "606px",
        api: "StandardList",
        type: "listThree"
      })
    ]),
    _createVNode(CommonShowPanel, {
      title: "友情链接",
      type: 2,
      list: _unref(linkList)
    }, null, 8, ["list"]),
    ((_unref(modalData) as any).isShow)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_icon_close_circle, {
            class: "pointer",
            onClick: _cache[0] || (_cache[0] = ($event: any) => ((_unref(modalData) as any).isShow = 0))
          }),
          _createVNode(_component_a_link, {
            href: (_unref(modalData) as any).toLink,
            target: "_blank"
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                class: "pointer",
                src: (_unref(modalData) as any).url
              }, null, 8, _hoisted_5)
            ]),
            _: 1
          }, 8, ["href"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})