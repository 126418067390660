import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "banner" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 0 }

import { onMounted, ref, getCurrentInstance, computed } from 'vue'

  
export default /*@__PURE__*/_defineComponent({
  __name: 'Banner',
  props: {
    api: {}
  },
  setup(__props: any) {

  const props = __props
  const { appContext: { config : { globalProperties } } } = (getCurrentInstance() as any);
  const baseUrl = computed(() => { return globalProperties.baseUrl })

 // 通过api判断修改配置
  let option = ref({})
  let images = ref([])

  onMounted(() => {
    showPage();
    
  })

  function showPage() {
    globalProperties.$API[props.api]().then((res: any) => {
      if(res.data.code == 200) {
        images.value = res.data.data;
        option.value = props.api == 'BannerList' ? {
          animation: 'fade',
          speed: 2000, 
          indicator: 'never',
          width: '1200px',
          height: '280px'
        } : {
          animation: 'slide',
          speed: 2000, 
          indicator: 'line',
          width: '570px',
          height: '380px'
        }
      }
    })
  }

  function eventClick(item: any) {
    window.open(item.toLink)
  }

return (_ctx: any,_cache: any) => {
  const _component_a_carousel_item = _resolveComponent("a-carousel-item")!
  const _component_a_carousel = _resolveComponent("a-carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(images).length > 0)
      ? (_openBlock(), _createBlock(_component_a_carousel, {
          key: 0,
          style: _normalizeStyle({
        width: (_unref(option) as any).width,
        height: (_unref(option) as any).height,
      }),
          "auto-play": true,
          "animation-name": (_unref(option) as any).animation,
          "show-arrow": "never",
          "indicator-type": (_unref(option) as any).indicator
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(images), (item, index) => {
              return (_openBlock(), _createBlock(_component_a_carousel_item, {
                key: index,
                class: "banner_item pointer",
                onClick: ($event: any) => (eventClick(item))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    src: baseUrl.value + (item as any).url,
                    style: { width: '100%' }
                  }, null, 8, _hoisted_2),
                  (props.api != 'BannerList' && (item as any).title)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString((item as any).title), 1))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["style", "animation-name", "indicator-type"]))
      : _createCommentVNode("", true)
  ]))
}
}

})