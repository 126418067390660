import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "news_tab" }
const _hoisted_2 = ["onMouseenter"]
const _hoisted_3 = { class: "new_list" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "more" }

import { CONST } from '@/types/const'
  import { computed, watch, ref, getCurrentInstance } from 'vue';
  import { useRouter } from 'vue-router';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'NewListPanel',
  props: {
    type: {},
    api: {},
    width: {},
    list: {}
  },
  setup(__props: any) {

  const props = __props
  const router = useRouter()
  const { appContext: { config : { globalProperties } } } = (getCurrentInstance() as any);

  let tabIndex = ref(props.type ? (CONST.newsTabList as any)[props.type][0].type : (props.list as any)[0].id)
  let link = ref(props.type ? 'standardDynamics' : 'announcement')

  watch(() => tabIndex.value, (n, o) => {
    if((n == 0 || n) && n != o) {
      if(props.api) {
        globalProperties.$API[props.api]({
          pageNum: 1,
          pageSize: 6,
          articleClassificationId: n,
          sort: n,
          state: 1,
          standardClassificationList: ["国家标准","行业标准","地方标准","企业标准","团体标准"],
        }).then((res: any) => {
          if(res.data.code == 200) {
            if(res.data.data.results) res.data.data.results.forEach((item: any) => {
              item.date =  n == 0 ? item.release_date && item.release_date.split(' ')[0] || '-' : 
              n == 2 ? item.implement_date && item.implement_date.split(' ')[0] || '-' : 
              item.abolish_date && item.abolish_date.split(' ')[0] || '-';
              item.title = `<span style="margin-right: 10px;">${item.standard_number}</span> ${(item.chinese_name || item.english_name)}`
            })
            newsList.value = res.data.data.records || res.data.data.results
          }
        })
      }
    }
  }, {
    immediate: true
  })
  
  let newsList = ref([])

  function tabClick(item: any) {
    tabIndex.value = item.id || item.type
  }

  function moreClick() {
    router.push(`/${link.value}/${  tabIndex.value }`)
  }

  function eventClick(item: any) {
    if(link.value == 'standardDynamics') {
      router.push(`/standardDetail?number=${ item.standard_number }`)
    }else {
      router.push(`/${link.value + 'Detail'}/${ item.id }`)
    }
    
  }



return (_ctx: any,_cache: any) => {
  const _component_icon_right = _resolveComponent("icon-right")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: "new_list_panel",
    style: _normalizeStyle({ width: _ctx.$props.width })
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$props.list || (_unref(CONST).newsTabList as any)[_ctx.$props.type as any], (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(['item_tab pointer', _unref(tabIndex) == (item.type || item.id) ? 'active' : '']),
          key: item.type || item.id,
          onMouseenter: ($event: any) => (tabClick(item))
        }, [
          _createElementVNode("span", null, _toDisplayString(item.title || item.classificationName), 1)
        ], 42, _hoisted_2))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(newsList), (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "news_item pointer",
          key: (item as any).id,
          onClick: ($event: any) => (eventClick(item))
        }, [
          _cache[0] || (_cache[0] = _createElementVNode("span", null, null, -1)),
          _createElementVNode("p", {
            innerHTML: (item as any).title
          }, null, 8, _hoisted_5),
          _createElementVNode("span", null, _toDisplayString((item as any).date || (item as any).createDate), 1)
        ], 8, _hoisted_4))
      }), 128))
    ]),
    _createElementVNode("p", _hoisted_6, [
      _createVNode(_component_a_button, {
        type: "text",
        onClick: moreClick
      }, {
        default: _withCtx(() => [
          _cache[1] || (_cache[1] = _createTextVNode("更多")),
          _createVNode(_component_icon_right)
        ]),
        _: 1
      })
    ])
  ], 4))
}
}

})